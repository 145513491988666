<template>
    <div class="action-schedule">
        <template>
            <div class="message">
                <div class="message-top">
                    <img :src="require('@/assets/images/icons/hello.png')" alt="" />
                    <span>장소가 확정되었어요!</span>
                </div>
                <div class="message-content">
                    <div class="grey-box m-t-8">
                        <div class="user-profile">
                            <img :src="photoUrl" alt="" />
                            <div class="profile-info">
                                <div class="age" v-html="age" />
                                <div class="region" v-html="content.region" />
                                <div class="job" v-html="content.job" />
                            </div>
                        </div>
                    </div>
                    <div class="grey-box m-t-8 p-l-16">
                        <div class="title-grey">약속 내용</div>
                        <div class="content-grey">
                            <p class="m-b-4">{{ time }}</p>
                            <p>{{ content.meet_place }}에서 {{ meetType }}예정</p>
                        </div>
                    </div>
                    <!-- <div class="pink-box m-t-8 p-l-16">
                        <div class="title-pink">여성분 음식 선호 사항</div>
                        <div class="content-pink">{{ foodPrefer }}</div>
                    </div> -->
                    <div class="green-box m-t-8 p-l-16">
                        <div class="title-green">장소</div>
                        <div class="content-green m-r-4">
                            <div class="m-r-4">{{ content.restaurant_name }}</div>
                            <div class="place-map">
                                <img
                                    :src="require('@/assets/images/icons/location.png')"
                                    alt=""
                                    widtt="16"
                                    height="16"
                                />

                                <div @click="showMap">지도보기</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'ActionScheduleReservationComplete',
    props: ['message'],
    computed: {
        content() {
            return this.$mustParse(this.message.content)
        },
        photoUrl() {
            return this.content.urls[0] || null
        },
        age() {
            return `${this.$options.filters.asAge(this.content.birthday)}세`
        },
        time() {
            const dateConfirmed = this.content.date_confirmed
            const date = this.$moment(dateConfirmed).format('YY/MM/DD')
            const day = this.$moment(dateConfirmed).format('dddd').toUpperCase()
            const time = this.$moment(dateConfirmed).format('A h시 mm분')

            return `${date} ${this.$translate(day)}, ${time}`
        },
        promise() {
            const dateConfirmed = this.content.date_confirmed
            return (
                this.$moment(dateConfirmed).format(`M월 D일 h시`) +
                (Number(this.$moment(dateConfirmed).format('mm')) > 0 ? this.$moment(dateConfirmed).format('mm분') : '')
            )
        },
        foodPrefer() {
            if (this.content.foods_unpreferred_status === 0) {
                return '다 잘먹어요'
            } else {
                return this.content.foods_unpreferred
            }
        },
        meetType() {
            const fixed = ['coffee', 'meal']

            return fixed.includes(this.content.meet_type_confirmed)
                ? this.$translate(this.content.meet_type_confirmed.toUpperCase())
                : '커피 또는 식사'
        },
    },

    methods: {
        async openModalMeetingPlaceInput() {
            await this.$modal.custom({
                component: 'ModalMeetingPlaceInput',
            })
            this.$store.dispatch('loadChat', {
                chatId: this.message.chat_id,
                force: true,
            })
        },
        showMap() {
            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: this.content.restaurant_url,
            })
        },
    },
}
</script>
<style scoped lang="scss">
.desc {
    font-size: 14px;
}
.message-top {
    display: flex;
    align-items: flex-start;
}
.message-content {
    .bold {
        font-weight: 700;
    }
    .content-green {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 24px;
        .place-map {
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2px 4px;
            min-width: 69px;
            width: fit-content;
            height: 24px;
            padding: 2px 4px;
            background: #04c9b2;
            border-radius: 100px;
        }
    }
}
</style>
